<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body" :style="intraction">
        <!-- BOC:[error] -->
        <ApiErrorDialog v-if="api.isError" :api="api" />
        <ApiErrorDialog v-if="unlockApi.isError" :api="unlockApi" />
        <!-- EOC -->
        <v-container class="app-body">
          <div class="pe-2 ps-3 app-title text-h5">
            {{ $t("title.locksmith") }}
            <v-spacer></v-spacer>
            <CloseBtn size="40" :callback="exit" />
          </div>
          <div>
            <v-img
              class="d-flex align-center mx-auto"
              :src="require('@/assets/explore/locksmith.png')"
              contain
            >
              <div class="d-flex align-center mx-auto">
                <AbstractAvatar
                  :avatar="studentAvatar"
                  :height="180"
                  :width="180"
                  class="ms-8"
                ></AbstractAvatar>
                <v-img
                  v-if="selectedItem"
                  max-width="150"
                  width="80"
                  contain
                  class="mx-auto mt-12"
                  sty
                  :src="selectedItem.images.icon.url"
                ></v-img>
              </div>
            </v-img>
            <div style="height: 50px; margin-top: -50px; margin-bottom: 20px">
              <div class="pa-2 text-center">
                <v-btn
                  v-if="selectedItem"
                  :loading="unlockApi.isLoading"
                  large
                  color="yellow"
                  class="text-h5 px-8"
                  @click="unlock"
                  id="BuyNow"
                >
                  {{ $t("action.unlock") }}
                </v-btn>
              </div>
            </div>

            <div class="items-panel pa-3">
              <div class="d-flex justify-space-between pa-3">
                <div>
                  <v-btn
                    icon
                    @click="previousPage()"
                    :disabled="currentPage == 1"
                  >
                    <v-img
                      :class="currentPage == 1 ? 'disabled-item-image' : null"
                      contain
                      max-width="40"
                      class="mx-auto"
                      :src="require('@/assets/island/ArrowButtonPre.png')"
                    ></v-img>
                  </v-btn>
                </div>
                <div class="text-stroke-md text-h5">
                  {{ $t("string.page") }} {{ currentPage }} / {{ totalPage }}
                </div>
                <div>
                  <v-btn
                    icon
                    @click="nextPage()"
                    :disabled="totalPage <= 1 || currentPage == totalPage"
                  >
                    <v-img
                      :class="
                        totalPage <= 1 || currentPage == totalPage
                          ? 'disabled-item-image'
                          : null
                      "
                      contain
                      max-width="40"
                      class="mx-auto"
                      :src="require('@/assets/island/ArrowButtonNex.png')"
                    ></v-img>
                  </v-btn>
                </div>
              </div>
              <div>
                <div>
                  <v-row class="mx-3">
                    <v-col
                      class="px-1 py-3"
                      cols="3"
                      @click="selectItem(item)"
                      v-for="(item, i) in slicedData"
                      :key="i"
                    >
                      <v-img
                        :class="item == selectedItem ? 'selected-chest' : ''"
                        contain
                        class="mx-auto"
                        :src="item.images.icon.url"
                      ></v-img>
                    </v-col>
                    <template v-if="slicedData && slicedData.length != 8">
                      <v-col
                        class="px-1 py-3"
                        cols="3"
                        v-for="j in 8 - slicedData.length"
                        :key="j + 'e'"
                      >
                        <Item
                          v-if="slicedData.length != 8"
                          :isDisabled="true"
                          :isShowQuantity="true"
                        />
                      </v-col>
                    </template>
                    <template v-if="!slicedData">
                      <v-col
                        class="px-1 py-3"
                        cols="3"
                        v-for="j in 8"
                        :key="j + 'e'"
                      >
                        <Item
                          :isDisabled="true"
                          :isShowQuantity="true"
                        />
                      </v-col>
                    </template>
                  </v-row>
                </div>
              </div>
            </div>
            <RewardDialog
              v-if="bought"
              :callbackClose="exitRwd"
              :showDialog="true"
              :rewards="rewards"
            />
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
  
  <script>
import { mapState } from "vuex";
import AvatarSkeleton from "@/components/skeleton/Avatar";
export default {
  computed: mapState({
    auth: (state) => state.auth.data,
    coin: (state) => state.coin.data,
    tutorial: (state) => state.tutorial.data,
  }),
  components: {
    AbstractAvatar: () => ({
      component: import(
        /* webpackChunkName: "component-abstract-avatar-testing" */ "@/components/avatar/AbstractAvatar"
      ),
      loading: AvatarSkeleton,
      delay: 200,
    }),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
    RewardDialog: () =>
      import(
        /* webpackChunkName: "component-reward" */ "@/components/games/RewardDialog"
      ),
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
    Item: () =>
      import(
        /* webpackChunkName: "component-shop-item" */ "@/components/shop/Item.vue"
      ),
  },
  data: () => ({
    rewards: [],
    bought: false,
    currentPage: 1,
    totalPage: 0,
    selectedItem: null,
    studentAvatar: {},
    intraction: null,
    items: [],
    itemData: [],
    slicedData: [],
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    unlockApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },

    //EOC
    avatar: {},
    authData: [],
  }),
  created() {
    this.avatar = this.$_.cloneDeep(JSON.parse(this.auth.Player.avatar));
    this.studentAvatar = this.$_.cloneDeep(this.auth.Player.avatar);
    this.api.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/locksmith/visit";
    this.unlockApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/locksmith/open";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.unlockApi.callbackReset = () => {
      this.unlockApi.isLoading = true;
      this.unlockApi.isError = false;
      this.unlockApi.error = null;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.unlockApi.callbackError = (e) => {
      this.unlockApi.isLoading = false;
      this.unlockApi.isError = true;
      this.unlockApi.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.authData = this.$_.clone(this.auth);
      this.api.isLoading = false;
      this.items = this.processItems(resp.Product);
      this.totalPage = Math.ceil(
        this.items.reduce((sum, obj) => sum + obj.quantity, 0) / 8
      );
      this.sliceItems();
    };
    this.unlockApi.callbackSuccess = (resp) => {
      this.selectedItem = null;
      this.rewards = resp.Reward.map((obj) => ({
        Item: {
          images: obj.images,
        },
        quantity: obj.quantity,
      }));
      this.unlockApi.isLoading = false;
      this.$api.fetch(this.api);
      this.bought = true;
    };
  },
  mounted() {
    this.$api.fetch(this.api);
  },
  methods: {
    paginateData(data, pageSize) {
      const result = [];
      const totalPages = Math.ceil(data.length / pageSize);

      for (let i = 0; i < totalPages; i++) {
        const start = i * pageSize;
        const end = start + pageSize;
        const page = data.slice(start, end);
        result.push(page);
      }

      return result;
    },
    exit() {
      this.$router.push({
        name: "PageExploreMenu",
      });
    },
    exitRwd() {
      this.bought = false;
    },
    unlock() {
      this.unlockApi.params = {
        itemKey: this.selectedItem.key,
      };
      this.$api.fetch(this.unlockApi);
    },
    selectItem(item) {
      this.selectedItem = item;
    },
    sliceItems() {
      this.slicedData = this.paginateData(this.items, 8)[this.currentPage - 1];
    },
    nextPage() {
      this.currentPage = this.currentPage + 1;
      this.sliceItems();
    },
    previousPage() {
      this.currentPage = this.currentPage - 1;
      this.sliceItems();
    },
    processItems(items) {
      const processedItems = [];

      items.forEach((item) => {
        const quantity = item.quantity;

        if (quantity === 0) {
          // Skip items with quantity 0
          return;
        }

        if (quantity === 1) {
          // Keep the item as is
          processedItems.push(item);
        } else {
          // Break the item into multiple items
          for (let i = 0; i < quantity; i++) {
            const newItem = { ...item };
            newItem.quantity = 1;
            processedItems.push(newItem);
          }
        }
      });
      return processedItems;
    },
  },
};
</script>
  
  <style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.coin-wrapper {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  max-width: 480px !important;
  width: 100%;
  z-index: 1;
}
.coin-panel {
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  z-index: 1;
  min-width: 120px;
  margin: 10px;
  right: 0px;
  padding: 2px 10px; /* remove this */
  background-color: #fff;
  text-align: right;
}
.burst {
  width: 100vw;
  height: 100vh;
  background: url("../../../assets/island/Burst.png") center bottom !important;
  background-size: cover !important;
  position: absolute;
  opacity: 0.6;
  z-index: 0;
}
.text-border {
  text-shadow: -1px 1px 0 rgb(255, 255, 255), 1px 1px 0 rgb(255, 255, 255),
    1px -1px 0 rgb(255, 255, 255), -1px -1px 0 rgb(255, 255, 255);
  z-index: 3;
}
.item-bar {
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 10px;
  margin-top: 10px;
}

.low-item-bar {
  background: rgb(255, 255, 255);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  border-radius: 10px;
  margin-top: 10px;
}
.avatar {
  height: 140px;
  overflow: hidden;
}
.crop {
  width: 70px;
  height: 30px;
  overflow: hidden;
}

.hat {
  width: 100px;
  height: 100px;
  margin: -10px 0 0 -10px;
}
.avatar-hat {
  position: absolute;
}

@media (max-height: 500px) {
  #shop {
    background-size: auto 500px !important;
  }
}

.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}

.text-stroke-md {
  color: #fff;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}
.items-panel {
  border: #caad87 5px solid;
  background-color: #f7efe3;
  padding-bottom: 30px !important;
}

.text-stroke-sm {
  color: #fff;
  text-shadow: #8b5629 3px 0px 0px, #8b5629 2.83487px 0.981584px 0px,
    #8b5629 2.35766px 1.85511px 0px, #8b5629 1.62091px 2.52441px 0px,
    #8b5629 0.705713px 2.91581px 0px, #8b5629 -0.287171px 2.98622px 0px,
    #8b5629 -1.24844px 2.72789px 0px, #8b5629 -2.07227px 2.16926px 0px,
    #8b5629 -2.66798px 1.37182px 0px, #8b5629 -2.96998px 0.42336px 0px,
    #8b5629 -2.94502px -0.571704px 0px, #8b5629 -2.59586px -1.50383px 0px,
    #8b5629 -1.96093px -2.27041px 0px, #8b5629 -1.11013px -2.78704px 0px,
    #8b5629 -0.137119px -2.99686px 0px, #8b5629 0.850987px -2.87677px 0px,
    #8b5629 1.74541px -2.43999px 0px, #8b5629 2.44769px -1.73459px 0px,
    #8b5629 2.88051px -0.838247px 0px;
}

.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 60px 0 0px 0;
  /* background-color: #ffb8b8; */
  position: relative;
}

.main-body {
  /* background-color: #ffb8b8; */
  background-color: rgba(255, 71, 123, 0.3);
}

.save-confirm {
  position: fixed;
  background-color: #ffb8b8;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
}

#shop {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.buy-confirm {
  background-color: rgba(0, 0, 0, 0.7);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.selected-chest {
  background-color: #8b572977;
  border-radius: 30px;
}

#shop-app {
  background: transparent !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.shop-plot {
  background: rgba(0, 0, 0, 0.2);
  width: calc(100% - 20px);
  margin: 0 10px;
  padding: 15px;
  border-radius: 10px;
}

.welcome-enter-active {
  animation: welcome-in 0.5s;
}
.welcome-leave-active {
  animation: welcome-out 0.5s reverse;
}
@keyframes welcome-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes welcome-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.low_graphics {
  background: none !important;
}

.low_island_plot {
  background-color: rgba(255, 255, 255, 0.5) !important;
  border-radius: 10px;
  /* padding: 20px !important; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}
.low_island_title_plot {
  background-color: rgb(246, 246, 246) !important;
  border-radius: 10px;
  padding: 5px !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  margin: 20px;
}
.low_island_building_plot {
  background-color: rgb(246, 246, 246) !important;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}
.low_island_coin_plot {
  background-color: rgb(246, 246, 246) !important;
  width: 100%;
  margin-right: 10px;
  padding: 5px 2%;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}

.low_plot {
  background: rgb(255, 255, 255) !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  border-radius: 10px !important;
  margin-top: 10px !important;
}
.plot {
  background: rgb(255, 255, 255) !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset !important;
  border-radius: 10px !important;
  margin-top: 10px !important;
}

.disabled-item-image {
  filter: brightness(0%) contrast(10%) !important;
}
</style>